import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Home from 'Features/Home';
import Signup from 'Features/Signup';
import SignupSuccess from 'Features/Signup/SignupSuccess/SignupSuccess';
import SignupForm from 'Features/Signup/SignupForm/SignupForm';
import Company from 'Features/Company';
import Contacts from 'Features/Contacts';
import Boutique from 'Features/Boutique';
import BoutiqueService from 'Features/Boutique/BoutiqueService';
import Academy from 'Features/Academy';
import DoveSiamo from 'Features/Academy/DoveSiamo';
import GbPro from 'Features/Academy/GbPro';
import GbProCourse from 'Features/Academy/GbPro/GbProCourse';
import LaTruccheria from 'Features/Academy/LaTruccheria';
import LaTruccheriaCourse from 'Features/Academy/LaTruccheria/LaTruccheriaCourse';
import Profile from 'Features/Profile';
import OrderDetail from 'Features/Profile/OrderDetail/OrderDetail';
import Cart from 'Features/Profile/Cart/Cart';
import Result from 'Features/Profile/Cart/Result/Result';
import CartPayment from 'Features/Profile/Cart/Payment/Payment';
import Subscribe from 'Features/Profile/Subscribe';
import SubscriptionPayment from 'Features/Profile/Subscribe/SubscriptionPayment';
import Catalog from 'Features/Catalog';
import Product from 'Features/Product';
import CentriMufe from 'Features/CentriMufe/CentriMufe';
import MufeMap from 'Features/CentriMufe/MufeMap/MufeMap';
import Agenti from 'Features/Agenti/Agenti';
import AgentiList from 'Features/Agenti/AgentiList';
import PasswordResetAnon from 'Features/PasswordResetAnon';
import PasswordChange from 'Features/Profile/components/PasswordChange/PasswordChange';
import MailChange from 'Features/Profile/components/MailChange/MailChange';
import Category from 'Features/Catalog/Category';
import ForgotPassword from 'Features/ForgotPassword/ForgotPassword';
import SingolaData from 'Features/Academy/SingolaData/SingolaData';
import Wishlist from 'Features/Profile/Wishlist/Wishlist';
import Novita from 'Features/Novita/Novita';
import GenericPrivacyPage from 'Features/Privacy/GenericPrivacyPage';
import Search from 'Features/Ricerca/RicercaDetail';
import CentroMufe from 'Features/CentriMufe/CentroMufe';

// All styling goes here
import './shared/styles/normalize.css';
import './shared/styles/slick.css';
import './assets/fonts/icomoon/icomoon.css';

// to split into its own comps
import './shared/styles/style.css';
import './shared/styles/responsive.css';

import CatchAllRoute from './CatchAllRoute';
import Loader from './shared/components/Loader';
import BootstrapLogic from './shared/components/BootstrapLogic';
import { store, persistor } from './store';
import ProtectedRoute from './shared/components/ProtectedRoute';
import MainMenu from './shared/components/MainMenu';
import FacebookPixel from './shared/components/FacebookPixel';
import CookieBot from 'react-cookiebot';

/* passed to transform objects:

AcademyHome
BoutiqueHome */

if (process.env.NODE_ENV === 'production') {
  // eslint-disable-next-line no-console
  console.log = () => { };
}

export default function App() {
  const [loginVisible, setLoginVisible] = useState(false);

  return (
    <Provider store={store}>
      <CookieBot domainGroupId={process.env.REACT_APP_COOKIEBOT_DOMAIN_GROUP_ID} />
      <PersistGate loading={<Loader />} persistor={persistor}>
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}>
          <Router>
            <BootstrapLogic loader={<Loader />}>
              <MainMenu
                setLoginVisible={setLoginVisible}
                loginVisible={loginVisible}
              />
              <FacebookPixel />
              <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/homepage" exact component={Home} />
                <Route
                  path="/cookie_policy"
                  exact
                  component={props => (
                    <GenericPrivacyPage
                      {...props}
                      objProps="cookie_policy.body_cookie_policy"
                    />
                  )}
                />
                <Route
                  path="/privacy"
                  exact
                  component={props => (
                    <GenericPrivacyPage
                      {...props}
                      objProps="privacy.body_privacy"
                    />
                  )}
                />
                <Route
                  path="/termini_condizioni"
                  exact
                  component={props => (
                    <GenericPrivacyPage
                      {...props}
                      objProps="termini_condizioni.body_termini_condizioni"
                    />
                  )}
                />
                <Route
                  path="/password/reset/confirm"
                  component={PasswordResetAnon}
                />
                <Route path="/password/reset" component={ForgotPassword} />
                <Route
                  path="/academy_signup/:courseID/:courseName"
                  exact
                  component={props => (
                    <SignupForm {...props} setLoginVisible={setLoginVisible} />
                  )}
                />
                <Route path="/signup" exact component={Signup} />
                <Route
                  path="/signup/:type"
                  exact
                  component={props => (
                    <SignupForm {...props} setLoginVisible={setLoginVisible} />
                  )}
                />
                <Route path="/signup-success" exact component={SignupSuccess} />
                <Route path="/company" exact component={Company} />
                <Route path="/contatti" exact component={Contacts} />
                <Route
                  path="/contatti/:category/:ragsoc"
                  exact
                  component={Contacts}
                />
                <Route path="/boutique" exact component={Boutique} />
                <Route path="/boutique/:service" component={BoutiqueService} />
                <Route path="/academy_homepage" exact component={Academy} />
                <Route
                  path="/academy_homepage/dove/:citta"
                  exact
                  component={DoveSiamo}
                />
                <Route path="/academy-gb-pro" exact component={GbPro} />
                <Route
                  path="/academy-gb-pro/:course"
                  exact
                  component={GbProCourse}
                />
                <Route
                  path="/academy-la-truccheria"
                  exact
                  component={LaTruccheria}
                />
                <Route
                  path="/academy-la-truccheria/:course"
                  exact
                  component={LaTruccheriaCourse}
                />
                <Route
                  path="/academy/corso/:course"
                  exact
                  component={SingolaData}
                />
                <Route path="/centri_mufe" exact component={CentriMufe} />
                <Route path="/centri_mufe/:search" exact component={MufeMap} />
                <Route path="/centro_mufe/:slug" exact component={CentroMufe} />
                <Route path="/distribuzione" exact component={Agenti} />
                <Route
                  path="/distribuzione/:search"
                  exact
                  component={AgentiList}
                />
                <Route path="/catalog" exact component={Catalog} />
                <Route path="/catalog/:slug" component={Category} />
                <Route path="/product/:slug" component={Product} />
                <Route path="/payment" exact component={SubscriptionPayment} />
                <Route path="/payment/:id" component={SubscriptionPayment} />
                <Route path="/cerca/:term" component={Search} />
                <Route path="/novita" exact component={Novita} />
                <ProtectedRoute path="/profile" exact component={Profile} />
                <ProtectedRoute
                  path="/profile/password_change"
                  component={PasswordChange}
                />
                <ProtectedRoute
                  path="/profile/mail_change"
                  component={MailChange}
                />
                <ProtectedRoute
                  path="/subscribe/:course"
                  component={Subscribe}
                />
                <ProtectedRoute path="/ordine/:ordID" component={OrderDetail} />
                <ProtectedRoute path="/wishlist" exact component={Wishlist} />
                <ProtectedRoute path="/cart" exact component={Cart} />
                <ProtectedRoute
                  path="/cart/payment/:type"
                  exact
                  component={CartPayment}
                />
                <ProtectedRoute
                  path="/cart/checkout/:status"
                  exact
                  component={Result}
                />
                <Route component={CatchAllRoute} />
              </Switch>
            </BootstrapLogic>
          </Router>
        </GoogleReCaptchaProvider>
      </PersistGate>
    </Provider>
  );
}
